import React, { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import {
  FaChartLine,
  FaBullhorn,
  FaLaptopCode,
  FaEnvelope,
  FaFileAlt,
  FaTools,
} from "react-icons/fa";

const DigitalMarketing = () => {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  return (
    <div className="bg-gray-50">
      {/* Hero Section */}
      <div
        className="relative h-screen flex items-center  bg-cover bg-center"
        style={{
          backgroundImage: `url('https://ik.imagekit.io/tdlebsr5e/2150063150.jpg?updatedAt=1731392338899')`,
        }}
      >
        {/* <div className="absolute inset-0 bg-black opacity-60"></div>
        <div className="relative z-10 text-left text-white">
          <h1 className="text-6xl font-bold mb-4">
            Digital Marketing
          </h1>
          <p className="text-2xl">
          Drive Growth with Our Expert Digital Marketing Solutions
          </p>
        </div> */}
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="relative z-10 text-left p-8">
          <h1
            className="text-5xl md:text-7xl text-white font-bold"
            data-aos="zoom-in-left"
          >
            Digital Marketing
          </h1>
          <p className="text-sky-400 my-6 font-semibold text-lg tracking-wider">
          Drive Growth with Our Expert Digital Marketing Solutions
          </p>
        </div>
      </div>

      {/* Introduction Section */}
      <section className="py-16 lg:px-16  bg-gradient-to-r from-purple-300 to-pink-200  text-black">
        {/* Top Section: Content Left, Image Right */}
        <div className="flex flex-wrap mb-16">
          <div className="w-full md:w-1/2 px-6 text-left">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">
              Embrace the Power of Digital Advertising Solutions
            </h2>
            <p className="text-base md:text-lg mb-4 max-w-3xl">
              In an era where consumers turn to the internet for information and
              shopping, digital marketing is no longer optional—it’s essential.
              It allows you to reach your audience directly, engage with them in
              real-time, and measure your success with precision.
            </p>
            <p className="text-base md:text-lg mb-4 max-w-3xl">
              At Adbrusher Media, our expert team crafts innovative digital
              marketing strategies tailored to your unique goals. From targeted
              ad campaigns to insightful analytics, we help you navigate the
              digital world and foster lasting connections with your audience.
            </p>
           
          </div>
          <div className="w-full md:w-1/2 px-6 flex justify-center items-center">
            <img
              src="https://ik.imagekit.io/tdlebsr5e/2150063136.jpg?updatedAt=1729148709453"
              alt="Digital marketing illustration"
              className="w-full max-w-lg h-auto"
            />
          </div>
        </div>

        {/* Bottom Section: Image Left, Content Right */}
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 px-6 flex justify-center items-center">
            <img
              src="https://ik.imagekit.io/tdlebsr5e/167088.jpg?updatedAt=1729148992800" // Replace with a different image if needed
              alt="Digital marketing illustration"
              className="w-full max-w-lg h-auto"
            />
          </div>
          <div className="w-full md:w-1/2 px-6 text-left">
          <h2 className="text-3xl md:text-3xl font-bold mb-4">
              Strengthen User Relationships
            </h2>
            <p className="text-base md:text-lg mb-4 max-w-3xl">
              Our analytics reveal key user insights, enabling you to understand
              and address audience needs for stronger connections.
            </p>
            <h2 className="text-3xl md:text-3xl font-bold mb-4">
              Drive More Traffic
            </h2>
            <p className="text-base md:text-lg mb-4 max-w-3xl">
              We create cross-channel opportunities that enhance user experience
              and increase traffic to your website.
            </p>
            <h2 className="text-3xl md:text-3xl font-bold mb-4">
              Stay Ahead of the Game
            </h2>
            <p className="text-base md:text-lg mb-4 max-w-3xl">
              Our multi-channel approach ensures seamless connectivity, keeping
              you at the forefront of market trends.
            </p>
            <h2 className="text-3xl md:text-3xl font-bold mb-4">
              Compete with Confidence
            </h2>
            <p className="text-base md:text-lg mb-4 max-w-3xl">
              We empower small to mid-sized enterprises to compete effectively
              with multinational corporations.
            </p>
           
          </div>
        </div>
      </section>

      {/* Marketing Facts Section */}
      <section className="py-16 bg-white text-center">
        <h2 className="text-4xl font-bold mb-10 text-gray-800 py-10">
          Our Digital Marketing Services For Your 100X Growth
        </h2>
        <div className="flex flex-wrap justify-center space-x-6 space-y-12 md:space-y-0">
          <div className="flex flex-col items-start text-gray-700 lg:w-64 sm:w-72 text-left">
            <FaChartLine className="text-6xl mb-4 text-blue-600" />
            <h3 className="text-2xl font-semibold mb-2">
              Search Engine Optimization (SEO)
            </h3>
            <p className="mt-2 max-w-lg text-justify">
              Boost your visibility with our expert SEO strategies! We focus on
              improving your search engine rankings, driving organic traffic,
              and making your brand shine in search results through effective
              keyword research and on-page optimization.
            </p>
          </div>

          <div className="flex flex-col items-start text-gray-700 lg:w-64 sm:w-72 text-left">
            <FaBullhorn className="text-6xl mb-4 text-green-600" />
            <h3 className="text-2xl font-semibold mb-2">
              Social Media Marketing
            </h3>
            <p className="mt-2 max-w-lg text-justify">
              Transform your brand engagement with our Digital Marketing Firm!
              We craft content that sparks conversation, manage your platforms,
              and analyze performance to keep your audience connected and
              engaged.
            </p>
          </div>

          <div className="flex flex-col items-start text-gray-700 lg:w-64 sm:w-72 text-left">
            <FaLaptopCode className="text-6xl mb-4 text-purple-600" />
            <h3 className="text-2xl font-semibold mb-2">
              Pay-Per-Click Advertising (PPC)
            </h3>
            <p className="mt-2 max-w-lg text-justify">
              Maximize your returns with targeted PPC campaigns! Our team
              expertly manages ads across Google and social media, ensuring your
              budget is spent wisely to attract the right customers at the right
              time.
            </p>
          </div>

          <div className="flex flex-col items-start text-gray-700 lg:w-64 sm:w-72 text-left">
            <FaFileAlt className="text-6xl mb-4 text-red-600" />
            <h3 className="text-2xl font-semibold mb-2">Content Marketing</h3>
            <p className="mt-2 max-w-lg text-justify">
              Engage your audience with compelling content! From blogs to
              infographics, our creators craft high-quality materials that
              inform and captivate, building strong connections through
              storytelling.
            </p>
          </div>

          <div className="flex flex-col items-start text-gray-700 lg:w-64 sm:w-72 text-left">
            <FaEnvelope className="text-6xl mb-4 text-yellow-600" />
            <h3 className="text-2xl font-semibold mb-2">Email Marketing</h3>
            <p className="mt-2 max-w-lg text-justify">
              Nurture your leads and boost loyalty with tailored email Digital
              Marketing Services! We design strategies that resonate with your
              audience, optimizing for better open rates and conversions with
              data-driven insights.
            </p>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section className="py-16 bg-gray-100 text-center">
        <h2 className="text-4xl font-bold mb-10 text-gray-800">
          Adbrusher Media: Your Trusted Partner in Digital Success
        </h2>
        <div className="text-lg leading-relaxed max-w-4xl mx-auto space-y-6  md:px-10 text-left">
          <ul className="list-disc list-inside space-y-4 text-gray-700">
            <li>
              We stay ahead with the latest trends to keep your marketing fresh.
            </li>
            <li>Our decisions are backed by analytics for maximum ROI.</li>
            <li>
              We align our strategies with your goals through close teamwork.
            </li>
            <li>A full suite of services for a cohesive marketing strategy.</li>
            <li>A track record of successful campaigns and happy clients.</li>
            <li>24/7 support to address your needs and concerns.</li>
          </ul>
        </div>
      </section>

      {/* Tools Section */}
      <section className="py-16 bg-gradient-to-r from-gray-200 to-gray-300 text-center">
        <h2 className="text-4xl font-bold mb-10 text-gray-800">
          The Powerhouse Tools Driving Our Services
        </h2>
        <div className="flex flex-wrap justify-center space-x-6 space-y-12 md:space-y-0">
          <div className="flex flex-col items-center text-gray-700 lg:w-64 sm:w-72 text-left">
            <FaTools className="text-5xl mb-4 text-blue-600" />
            <h3 className="text-2xl font-semibold">Semrush</h3>
            <p className="mt-4 max-w-lg">
              An all-in-one platform, Semrush enables comprehensive SEO audits
              and refines your strategy for optimal growth.
            </p>
          </div>
          <div className="flex flex-col items-center text-gray-700 lg:w-64 sm:w-72 text-left">
            <FaTools className="text-5xl mb-4 text-green-600" />
            <h3 className="text-2xl font-semibold">Ahrefs</h3>
            <p className="mt-4 max-w-lg">
              Powerful tools for site audits, competitor analysis, keyword
              research, and rank tracking to stay ahead.
            </p>
          </div>
          <div className="flex flex-col items-center text-gray-700 lg:w-64 sm:w-72 text-left">
            <FaTools className="text-5xl mb-4 text-red-600" />
            <h3 className="text-2xl font-semibold">Google Analytics</h3>
            <p className="mt-4 max-w-lg">
              Provides invaluable insights into how users interact with your
              website, helping you make data-driven decisions.
            </p>
          </div>
          <div className="flex flex-col items-center text-gray-700 lg:w-64 sm:w-72 text-left">
            <FaTools className="text-5xl mb-4 text-yellow-600" />
            <h3 className="text-2xl font-semibold">Screaming Frog SEO</h3>
            <p className="mt-4 max-w-lg">
              A versatile SEO spider tool that identifies technical SEO issues
              to optimize your site effectively.
            </p>
          </div>
          <div className="flex flex-col items-center text-gray-700 lg:w-64 sm:w-72 text-left">
            <FaTools className="text-5xl mb-4 text-purple-600" />
            <h3 className="text-2xl font-semibold">Buffer</h3>
            <p className="mt-4 max-w-lg">
              Simplifies social media management, allowing you to schedule and
              analyze posts across platforms.
            </p>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-16  bg-gradient-to-r from-purple-300 to-pink-200  text-black text-center">
        <h2 className="text-4xl font-bold mb-4">Contact Us Today!</h2>
        <p className="text-lg mb-8">
          Don’t let your business get lost in the digital noise. Contact us
          today for a free consultation and discover how we can help you grow.
        </p>
        <button className="bg-white text-blue-600 py-2 px-6 rounded-full font-semibold hover:bg-gray-100">
          Get in Touch
        </button>
      </section>
    </div>
  );
};

export default DigitalMarketing;
