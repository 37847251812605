import React, { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCompass,
  faPaintBrush,
  faCode,
  faRocket,
} from "@fortawesome/free-solid-svg-icons";
const MobileAppDevelopment = () => {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  return (
    <div>
      {/* Top Section with Background Image and Blue Tint */}
      <div
        className="relative h-screen py-32 flex items-center justify-start bg-cover bg-center px-4 sm:px-8 md:px-16 lg:px-32 lg:py-64"
        style={{
          backgroundImage: `url('https://ik.imagekit.io/tdlebsr5e/2150104516.jpg?updatedAt=1731391953576')`,
        }}
        data-aos="slide-in"
      >
        {/* <div className="absolute inset-0 bg-black bg-opacity-70"></div>
        <div className="absolute inset-0 bg-blue-800 bg-opacity-10"></div>
        <div className="relative z-10 text-left max-w-xl">
          <h1
            className="text-5xl sm:text-6xl md:text-6xl text-white font-bold"
            data-aos="zoom-in-left"
          >
             Mobile App Development
          </h1> */}
          {/* <p className="text-sky-400 my-6 font-semibold text-lg md:text-xl tracking-wider">
            YOUR VISION, YOUR EXPERTISE
          </p> */}
          {/* <p className="text-white text-lg md:text-xl leading-relaxed">
          At Adbrusher Media, we are dedicated to creating innovative, user-friendly mobile applications that engage and retain users.
          </p>
        </div> */}
          <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="relative z-10 text-left p-8">
          <h1
            className="text-5xl md:text-7xl text-white font-bold"
            data-aos="zoom-in-left"
          >
              App Development
          </h1>
          <p className="text-sky-400 my-6 font-semibold text-lg tracking-wider">
          Your Vision, Our Expertise

        Premier App Development Company
          </p>
        </div>
      </div>

      <div className="bg-white text-black">
      <div className=" bg-gradient-to-r from-purple-300 to-pink-200">
      <h1 className="text-4xl font-bold text-center py-7">
      Our Comprehensive App Development Services
            </h1>
        {/* Introduction Section */}
        <section
          className="py-20 px-8 md:px-16 lg:px-24 xl:px-32 flex flex-col md:flex-row md:space-x-12 items-center  bg-gradient-to-r from-purple-300 to-pink-200 "
          data-aos="slide-up"
        >
          <div className="md:w-1/2 flex flex-col items-center text-center md:items-start md:text-left mb-10 md:mb-0">
            <p className="text-lg mb-8 max-w-lg mx-auto md:mx-0">
            <strong>Custom App Development:</strong> As a leading app development firm, we specialize in crafting tailored applications designed to fit your unique business model and user needs. Our team takes the time to understand your objectives, allowing us to build scalable and robust solutions.
            </p>
            <p className="text-lg mb-8 max-w-lg mx-auto md:mx-0">
            <strong>E-commerce Solutions:</strong> Our robust e-commerce apps transform the shopping experience, making it seamless and enjoyable for your customers. With our expertise, your e-commerce platform will be equipped to handle everything from inventory management to customer engagement.

            </p>
            <div className="flex justify-center md:justify-start mb-8">
              <img
                src="https://images.pexels.com/photos/1181244/pexels-photo-1181244.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="PPC Service"
                className="max-h-96 rounded-lg transition-transform transform hover:scale-105"
              />
            </div>
          </div>

          <div className="md:w-1/2 flex flex-col items-center text-center md:items-start md:text-left mt-10 md:mt-0">
            <div className="flex justify-center md:justify-start mb-8">
              <img
                src="https://images.pexels.com/photos/1181263/pexels-photo-1181263.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load"
                alt="PPC Benefits"
                className="max-h-96 rounded-lg transition-transform transform hover:scale-105"
              />
            </div>
            <p className="text-lg mb-8 max-w-lg mx-auto md:mx-0">
            <strong>Enterprise Mobility Solutions:</strong> Our enterprise mobility solutions are designed to optimize operations, enhance collaboration, and boost productivity. We develop applications that streamline workflows, improve communication, and facilitate data sharing across departments.
            </p>
            <p className="text-lg mb-8 max-w-lg mx-auto md:mx-0">
            <strong>User Interface & Experience Design:</strong> Our user interface and experience design services focus on creating captivating and functional designs that engage users from the moment they open your app. We employ the latest design principles to ensure that navigation is intuitive and delightful.
            </p>
            {/* <p className="text-lg mb-8 max-w-lg mx-auto md:mx-0">
            <strong>Marketing and Growth Strategies:</strong> As part of our app development services, we offer tailored marketing and growth strategies to ensure your app reaches the right audience and achieves its full potential. We continuously analyze performance metrics to refine our approach over time.
            </p> */}
          </div>
        </section>
</div>


        {/* Importance of Custom App Development */}
        <section class="max-w-6xl mx-auto px-6 py-12 bg-white" data-aos="fade-up">
  <h2 class="text-3xl font-bold text-center text-gray-800 mb-6">
    Our Methodology Makes Us #1 App Development Firm
  </h2>
  
  <p class="text-center text-lg md:text-xl text-gray-600 mb-10">
    At Adbrusher Media, we are not just an app development company; we are your partners in crafting exceptional digital experiences. Our mission is to transform your ideas into powerful mobile applications that engage users and drive business growth.
  </p>
  
  <ul class="grid grid-cols-1 md:grid-cols-2 gap-6">
    <li class="p-6 bg-gray-100 rounded-lg shadow hover:bg-gray-200 transition duration-200">
      <h3 class="font-semibold text-gray-800 mb-2">
        Exploration and Ideation
      </h3>
      <p class="text-gray-600">
        We kick off the journey with a deep dive into your vision. Through collaborative brainstorming sessions and thorough market analysis, we refine your ideas into a clear roadmap for success. Our app development services are designed to align perfectly with your business goals.
      </p>
    </li>

    <li class="p-6 bg-gray-100 rounded-lg shadow hover:bg-gray-200 transition duration-200">
      <h3 class="font-semibold text-gray-800 mb-2">
        Design and Prototyping
      </h3>
      <p class="text-gray-600">
        Our talented designers craft stunning wireframes and prototypes that reflect your brand’s essence. We encourage your feedback at this stage, allowing us to fine-tune the design before moving into development, ensuring that every detail meets your expectations.
      </p>
    </li>

    <li class="p-6 bg-gray-100 rounded-lg shadow hover:bg-gray-200 transition duration-200">
      <h3 class="font-semibold text-gray-800 mb-2">
        Agile Development
      </h3>
      <p class="text-gray-600">
        Utilizing agile methodologies, we ensure flexibility throughout the development process. Our team works in sprints, regularly delivering updates and inviting your input to keep the project aligned with your expectations. This approach enhances our app development services by fostering collaboration and adaptability.
      </p>
    </li>

    <li class="p-6 bg-gray-100 rounded-lg shadow hover:bg-gray-200 transition duration-200">
      <h3 class="font-semibold text-gray-800 mb-2">
        Rigorous Testing
      </h3>
      <p class="text-gray-600">
        Quality is non-negotiable. We conduct extensive testing to identify and resolve any bugs, ensuring that your app performs flawlessly on all devices and platforms. Our quality assurance team meticulously evaluates every aspect to deliver a polished final product, reinforcing the reliability of our app development services.
      </p>
    </li>

    <li class="p-6 bg-gray-100 rounded-lg shadow hover:bg-gray-200 transition duration-200">
      <h3 class="font-semibold text-gray-800 mb-2">
        Launch Strategy
      </h3>
      <p class="text-gray-600">
        When the time comes to launch, we don't just hand you the app—we partner with you to strategize a successful rollout. From app store optimization to targeted marketing strategies, we ensure your app gets the visibility it deserves in a competitive landscape.
      </p>
    </li>

    <li class="p-6 bg-gray-100 rounded-lg shadow hover:bg-gray-200 transition duration-200">
      <h3 class="font-semibold text-gray-800 mb-2">
        Ongoing Support and Enhancement
      </h3>
      <p class="text-gray-600">
        Our commitment to your success doesn’t end at launch. We offer continuous maintenance and updates, helping you adapt to user feedback and changing market trends. Together, we’ll keep your app relevant and engaging, ensuring the longevity of our app development services.
      </p>
    </li>
  </ul>
</section>


        {/* Our Methodology Section */}
        <section className="py-20 px-6 bg-gradient-to-r from-blue-50 to-indigo-50 text-center">
  <h2 className="text-4xl font-extrabold mb-12 text-gray-900">
    What Sets Our App Development Services Apart in the Industry?
  </h2>

  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 max-w-6xl mx-auto relative z-10">
    {[
      { text: "Customised Solutions for Every Business", icon: "fas fa-cogs" },
      { text: "Expertise Across Multiple Platforms", icon: "fas fa-layer-group" },
      { text: "Focus on Engaging User Experiences", icon: "fas fa-users" },
      { text: "Agile Development Methodologies", icon: "fas fa-running" },
      { text: "Rigorous Quality Assurance", icon: "fas fa-shield-alt" },
      { text: "Data-Driven Insights", icon: "fas fa-chart-line" },
      { text: "Strong Focus on Security", icon: "fas fa-lock" },
      { text: "Innovative Marketing Strategies", icon: "fas fa-bullhorn" },
    ].map((item, index) => (
      <div
        key={index}
        className="bg-white p-6 shadow-lg rounded-lg transform transition-all duration-300 hover:scale-105 hover:shadow-2xl text-left flex items-center"
      >
        <span className="text-blue-600 text-3xl mr-4">
          <i className={item.icon}></i>
        </span>
        <p className="text-gray-800 font-semibold text-lg">{item.text}</p>
      </div>
    ))}
  </div>

  {/* Subtle Background Shapes */}
  <div className="absolute top-0 left-0 w-32 h-32 bg-blue-200 opacity-30 rounded-full transform -translate-x-10 -translate-y-10 animate-pulse"></div>
  <div className="absolute bottom-0 right-0 w-40 h-40 bg-indigo-200 opacity-30 rounded-full transform translate-x-10 translate-y-10 animate-pulse"></div>
</section>


        {/* Call to Action Section */}
        <section
          className="py-20 px-4 text-center bg-gradient-to-r from-pink-500 to-blue-600 text-white"
          data-aos="fade-up"
        >
          <h2 className="text-3xl font-semibold mb-6">
            Ready to Start Your Project?
          </h2>
          <p className="text-lg mb-8">
            Contact us today to discuss how we can help you develop your dream
            app. Our team is dedicated to making your app a standout success.
          </p>
          <button className="bg-white text-blue-600 py-2 px-6 rounded-full font-bold hover:bg-gray-100 transition">
            Get Started
          </button>
        </section>
      </div>
    </div>
  );
};

export default MobileAppDevelopment;
