import React, { useEffect } from 'react';
import { HashRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import ContactUs from './Pages/Contactus';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import OurServices from './Pages/OurServices';
import Testimonial from './Pages/Testimonial';
import WebsiteDevelopment from './ServicePages/WebsiteDevelopment';
import SEO from './ServicePages/SEO';
import Company from './Pages/Company';
import MobileAppDevelopment from './ServicePages/MobileAppDevelopment';
import PPC from './ServicePages/PPC';
import ContentBranding from './ServicePages/ContentBranding';
import EmailMarketing from './ServicePages/EmaiMarketing';
import Home from './Pages/Home';
import SocialMedia from './ServicePages/SocialMedia';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TermsAndConditions from './Pages/TermsAndConditions';
import CookiePolicy from './Pages/CookiePolicy';
import FullfilmentPolicy from './Pages/FullfilmentPolicy';
import Blogs from './Components/Blogs';
import DigitalMarketing from './ServicePages/DigitalMarketing';
import Blog1 from './Blogs/Blog1';
import Blog2 from './Blogs/Blog2';
import Blog3 from './Blogs/Blog3';
import Blog4 from './Blogs/Blog4';
import DiscountModal from './Components/DiscountModal';
import FormData from './Pages/FormData';


const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null; // This component doesn't render anything
};

function Layout() {
  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/services" element={<OurServices />} />
        <Route path="/testinomials" element={<Testimonial />} />
        <Route path="/company" element={<Company />} />
        <Route path="/FormData" element={<FormData />} />
        {/*  */}
        <Route path="/services/website-development" element={<WebsiteDevelopment />} />
        <Route path="/services/seo-marketing" element={<SEO />} />
        <Route path="/services/mobile-app-development" element={<MobileAppDevelopment />} />
        <Route path="/services/ppc-ads-campaign" element={<PPC />} />
        <Route path="/services/content-branding" element={<ContentBranding />} />
        <Route path="/services/email-marketing" element={<EmailMarketing />} />
        <Route path="/services/social-media-strategy" element={<SocialMedia />} />
        <Route path="/services/digital-marketing" element={<DigitalMarketing />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
        <Route path="/services/CookiePolicy" element={<CookiePolicy />} />
        <Route path="/services/fulfillmentPolicy" element={<FullfilmentPolicy />} />
        {/* Blogs routes */}
        <Route path="/Blogs/Blog1" element={<Blog1 />} />
        <Route path="/Blogs/Blog2" element={<Blog2 />} />
        <Route path="/Blogs/Blog3" element={<Blog3 />} />
        <Route path="/Blogs/Blog4" element={<Blog4 />} />
      </Routes>
      <DiscountModal />
      <Blogs/>
      <Footer />
    </Router>
  );
}

export default Layout;
