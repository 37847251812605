import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [navbar, setNavbar] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [servicesDropdownOpen, setServicesDropdownOpen] = useState(false);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null); // Ref for the dropdown

  // Change background color on scroll
  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeBackground);
    return () => {
      window.removeEventListener("scroll", changeBackground);
    };
  }, []);

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
    setServicesDropdownOpen(false); // Close dropdown when mobile menu is closed
  };

  // Detect outside click to close mobile menu and dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        mobileMenuOpen &&
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        closeMobileMenu();
      }

      if (
        servicesDropdownOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setServicesDropdownOpen(false); // Close dropdown on outside click
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [mobileMenuOpen, servicesDropdownOpen]);

  return (
    <nav
      className={`fixed top-0 left-0 w-full z-50 transition-all duration-300 ease-in-out ${
        navbar
          ? "bg-gradient-to-r from-purple-400 to-pink-600"
          : "bg-transparent"
      }`}
    >
      <div className="container mx-auto flex items-center justify-between py-4 px-4 sm:px-6 md:px-2 xl:px-16">
        {/* Logo */}
        <Link to="/" className="flex items-center">
          <img
            src="https://ik.imagekit.io/6oa6qejxe/Screenshots/Untitled%20design%20(1).png?updatedAt=1727770602380"
            alt="Logo"
            className="h-8 w-auto sm:h-10 md:h-12 lg:h-14 transition-all duration-300 ease-in-out"
          />
          <span className="ml-2 text-white text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold">
            AdBrusher
          </span>
        </Link>

        {/* Navigation Links + Phone number */}
        <div className="hidden lg:flex items-center space-x-6 sm:space-x-8">
          <ul className="flex space-x-6 sm:space-x-8 text-white text-base sm:text-lg font-semibold">
            <li>
              <Link to="/" className="hover:text-gray-400">
                Home
              </Link>
            </li>
            <li className="relative">
              <button
                onClick={() => setServicesDropdownOpen(!servicesDropdownOpen)}
                className="hover:text-gray-400 focus:outline-none"
              >
                Services
              </button>
              {servicesDropdownOpen && (
                <div
                  ref={dropdownRef}
                  className="absolute left-0 mt-2 w-56 bg-gradient-to-r from-purple-500 to-pink-600 rounded-lg shadow-2xl z-10 transition-all duration-300 ease-in-out opacity-100"
                >
                  <ul className="flex flex-col text-white text-base sm:text-lg font-semibold border border-transparent rounded-lg overflow-hidden">
                    <li>
                      <Link
                        to="/services/website-development"
                        className="flex items-center px-4 py-3 text-left text-lg hover:bg-purple-700 transition-colors duration-200"
                        onClick={closeMobileMenu}
                      >
                        <svg
                          className="w-5 h-5 mr-3"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M12 2L10.586 3.414 14.172 7H2v2h12.172l-3.586 3.586L12 14l6-6-6-6z" />
                        </svg>
                        Website Development
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/services/seo-marketing"
                        className="flex items-center px-4 py-3 text-left hover:bg-purple-700 transition-colors duration-200"
                        onClick={closeMobileMenu}
                      >
                        <svg
                          className="w-5 h-5 mr-3"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M12 2L10.586 3.414 14.172 7H2v2h12.172l-3.586 3.586L12 14l6-6-6-6z" />
                        </svg>
                        SEO Marketing
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/services/mobile-app-development"
                        className="flex items-center px-4 py-3 text-left hover:bg-purple-700 transition-colors duration-200"
                        onClick={closeMobileMenu}
                      >
                        <svg
                          className="w-5 h-5 mr-3"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M12 2L10.586 3.414 14.172 7H2v2h12.172l-3.586 3.586L12 14l6-6-6-6z" />
                        </svg>
                        Mobile App Development
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/services/ppc-ads-campaign"
                        className="flex items-center px-4 py-3 text-left hover:bg-purple-700 transition-colors duration-200"
                        onClick={closeMobileMenu}
                      >
                        <svg
                          className="w-5 h-5 mr-3"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M12 2L10.586 3.414 14.172 7H2v2h12.172l-3.586 3.586L12 14l6-6-6-6z" />
                        </svg>
                        PPC Ads Campaign
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/services/content-branding"
                        className="flex items-center px-4 py-3 text-left hover:bg-purple-700 transition-colors duration-200"
                        onClick={closeMobileMenu}
                      >
                        <svg
                          className="w-5 h-5 mr-3"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M12 2L10.586 3.414 14.172 7H2v2h12.172l-3.586 3.586L12 14l6-6-6-6z" />
                        </svg>
                        Content Branding
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/services/email-marketing"
                        className="flex items-center px-4 py-3 text-left hover:bg-purple-700 transition-colors duration-200"
                        onClick={closeMobileMenu}
                      >
                        <svg
                          className="w-5 h-5 mr-3"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M12 2L10.586 3.414 14.172 7H2v2h12.172l-3.586 3.586L12 14l6-6-6-6z" />
                        </svg>
                        Email Marketing
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/services/social-media-strategy"
                        className="flex items-center px-4 py-3 text-left hover:bg-purple-700 transition-colors duration-200"
                        onClick={closeMobileMenu}
                      >
                        <svg
                          className="w-5 h-5 mr-3"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M12 2L10.586 3.414 14.172 7H2v2h12.172l-3.586 3.586L12 14l6-6-6-6z" />
                        </svg>
                        Social Media Strategy
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/services/digital-marketing"
                        className="flex items-center px-4 py-3 text-left hover:bg-purple-700 transition-colors duration-200"
                        onClick={closeMobileMenu}
                      >
                        <svg
                          className="w-5 h-5 mr-3"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M12 2L10.586 3.414 14.172 7H2v2h12.172l-3.586 3.586L12 14l6-6-6-6z" />
                        </svg>
                        Digital Marketing
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </li>

            <li>
              <Link to="/testinomials" className="hover:text-gray-400">
                Portfolio
              </Link>
            </li>
            <li>
              <Link to="/Blogs " className="hover:text-gray-400">
                Blogs
              </Link>
            </li>
            <li>
              <Link to="/company" className="hover:text-gray-400">
                Company
              </Link>
            </li>

            <li>
              <Link to="/contact" className="hover:text-gray-400">
                Contact Us
              </Link>
            </li>
          </ul>

          {/* Contact Number */}
          <div className="text-white text-base sm:text-xl font-semibold">
            <a href="tel:8003256364" className="hover:text-gray-400">
              +91-9915159299
            </a>
          </div>
        </div>

        {/* Mobile Menu Button */}
        <div className="lg:hidden text-white">
          <button
            ref={buttonRef}
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            className="focus:outline-none"
          >
            {mobileMenuOpen ? (
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            ) : (
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                ></path>
              </svg>
            )}
          </button>
        </div>
      </div>

      {/* Mobile Navigation Menu */}
      {mobileMenuOpen && (
        <div ref={menuRef} className="lg:hidden bg-darkblue py-6">
          <ul className="flex flex-col items-center space-y-6 text-white text-base sm:text-lg font-semibold">
            <li>
              <Link
                to="/"
                className="hover:text-gray-400"
                onClick={closeMobileMenu}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/services"
                className="hover:text-gray-400"
                onClick={closeMobileMenu}
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                to="/testinomials"
                className="hover:text-gray-400"
                onClick={closeMobileMenu}
              >
                Portfolio
              </Link>
            </li>
            <li>
              <Link
                to="/company"
                className="hover:text-gray-400"
                onClick={closeMobileMenu}
              >
                Company
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className="hover:text-gray-400"
                onClick={closeMobileMenu}
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
