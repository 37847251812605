import React, { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDesktop,
  faPaintBrush,
  faCode,
  faRocket,
} from "@fortawesome/free-solid-svg-icons";

const WebsiteDevelopment = () => {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  return (
    <div>
      {/* Top Section with Background Image and Blue Tint */}
      <div
        className="relative h-screen py-32 flex items-center justify-start bg-cover bg-center px-4 sm:px-8 md:px-16 lg:px-32 lg:py-64"
        style={{
          backgroundImage: `url('https://ik.imagekit.io/tdlebsr5e/18707.jpg?updatedAt=1731393970315')`,
        }}
        data-aos="slide-in"
      >
        {/* <div className="absolute inset-0 bg-black bg-opacity-70"></div>
        <div className="absolute inset-0 bg-blue-800 bg-opacity-10"></div>
        <div className="relative z-10 text-left max-w-xl">
          <h1
            className="text-5xl sm:text-6xl md:text-6xl text-white font-bold"
            data-aos="zoom-in-left"
          >
            Website Development
          </h1>
          <p className="text-sky-400 my-6 font-semibold text-lg md:text-xl tracking-wider">
           
          </p>
          <p className="text-white text-lg md:text-xl leading-relaxed">
            We believe your vision deserves a unique platform that matches your
            ambition.
          </p>
        </div> */}
          <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="relative z-10 text-left p-8">
          <h1
            className="text-5xl md:text-7xl text-white font-bold"
            data-aos="zoom-in-left"
          >
            Website Development
          </h1>
          <p className="text-sky-400 my-6 font-semibold text-lg tracking-wider">
          Best Website Development Company in India
          </p>
        </div>
      </div>

      <div className="bg-white text-black">
        {/* Introduction Section */}
<div className="bg-gradient-to-r from-purple-300 to-pink-200">
        <h1 className="text-4xl font-bold py-6 text-center ">
  The Importance of Website Design: Why Creativity Counts
</h1>

<section
  className="py-16 px-8 md:px-16 lg:px-24 xl:px-32 flex flex-col md:flex-row md:space-x-12 items-center"
  data-aos="fade-up"
>
  <div className="md:w-1/2 flex flex-col items-center text-center md:items-start md:text-left mb-10 md:mb-0">
    <p className="text-lg md:text-xl mb-8 max-w-lg mx-auto md:mx-0">
      Let us turn your ideas into reality with our expert website development services:
    </p>
    <ul className="list-disc list-inside text-lg md:text-xl max-w-lg mb-8 space-y-4 mx-auto md:mx-0 md:ml-4 lg:ml-6">
      <li>
        81% of brand marketers agree that creative effectiveness is the most crucial factor in a campaign’s success.
      </li>
      <li>
        57% of marketers have systems in place to evaluate the effectiveness of their creative efforts.
      </li>
      <li>
        64% of consumers believe that businesses should prioritize creating entertaining advertisements.
      </li>
      <li>
        75% of the impact of advertising is driven by the quality of the creative content.
      </li>
    </ul>
  </div>

  <div className="md:w-1/2 flex justify-center">
    <img
      src="https://images.pexels.com/photos/1181250/pexels-photo-1181250.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
      alt="Website development illustration"
      className="max-h-96 rounded-lg shadow-lg transition-transform transform hover:scale-105"
    />
  </div>
</section>
</div>


        {/* Importance of Custom Web Development */}
        <section class="max-w-6xl mx-auto px-6 py-12 bg-white" data-aos="fade-up">
  <h2 class="text-3xl font-bold text-center text-gray-800 mb-6">
    Our Website Development Services: Custom-Built for Your Success!
  </h2>
  
  <ul class="grid grid-cols-1 md:grid-cols-2 gap-6">
    <li class="p-6 bg-gray-100 rounded-lg shadow hover:bg-gray-200 transition duration-200">
      <h3 class="font-semibold text-gray-800 mb-2">
        Custom Website Development
      </h3>
      <p class="text-gray-600">
        We specialize in building websites from the ground up, meticulously crafted to reflect your brand’s identity and vision. Our custom web development services ensure that your site not only looks stunning but also functions flawlessly, providing a seamless experience for your visitors.
      </p>
    </li>

    <li class="p-6 bg-gray-100 rounded-lg shadow hover:bg-gray-200 transition duration-200">
      <h3 class="font-semibold text-gray-800 mb-2">
        E-commerce Solutions
      </h3>
      <p class="text-gray-600">
        We create secure, user-friendly platforms that facilitate smooth transactions and enhance the shopping experience. From intuitive navigation to secure payment gateways, we ensure that your customers enjoy a hassle-free journey from browsing to checkout.
      </p>
    </li>

    <li class="p-6 bg-gray-100 rounded-lg shadow hover:bg-gray-200 transition duration-200">
      <h3 class="font-semibold text-gray-800 mb-2">
        Content Management Systems (CMS)
      </h3>
      <p class="text-gray-600">
        Managing your website content should be straightforward and efficient. That’s why we integrate powerful CMS platforms like WordPress and Drupal, enabling you to update and manage your content without needing any technical expertise.
      </p>
    </li>

    <li class="p-6 bg-gray-100 rounded-lg shadow hover:bg-gray-200 transition duration-200">
      <h3 class="font-semibold text-gray-800 mb-2">
        Website Maintenance and Support
      </h3>
      <p class="text-gray-600">
        Our commitment to you extends beyond the launch of your website. We offer ongoing maintenance and support services to ensure your site remains up-to-date, secure, and performing at its best.
      </p>
    </li>

    <li class="p-6 bg-gray-100 rounded-lg shadow hover:bg-gray-200 transition duration-200">
      <h3 class="font-semibold text-gray-800 mb-2">
        Web Hosting Solutions
      </h3>
      <p class="text-gray-600">
        At Adbrusher Media, we provide fast and secure hosting services that guarantee your website is always accessible to visitors. With robust performance and top-notch security measures, you can focus on growing your business while we take care of the technical details.
      </p>
    </li>
  </ul>
</section>


        {/* Our Methodology Section */}
        <section className="py-16 px-4 text-center bg-gray-50">
        <h1 class="text-3xl font-bold text-center text-gray-800 mb-6">
        Discover the Difference: Why We’re Your Ideal Website Development Partner!
  </h1>
  <p class="text-lg text-gray-700 mb-8 text-center max-w-3xl mx-auto">
  Every business is unique, and so are its requirements. We take the time to understand your goals and target audience, crafting custom web development that aligns with your brand and objectives.
  </p>

          <div className="flex flex-wrap justify-center gap-8 mx-auto">
            {/* Discovery and Planning */}
            <div className="flex flex-col items-center max-w-xs text-center p-8 bg-white border border-gray-200 rounded-lg">
              <FontAwesomeIcon
                icon={faDesktop}
                className="text-5xl mb-4 text-blue-600"
              />
              <h3 className="text-2xl font-bold my-4 text-gray-800">
              Expert Team
              </h3>
              <p className="text-lg text-gray-700">
              Our skilled developers, designers, and digital strategists work collaboratively to ensure every aspect of your website is optimized for success. With years of experience, we stay ahead of the latest trends and technologies.
              </p>
            </div>

            {/* UI/UX Design */}
            <div className="flex flex-col items-center max-w-xs text-center p-8 bg-white border border-gray-200 rounded-lg">
              <FontAwesomeIcon
                icon={faPaintBrush}
                className="text-5xl mb-4 text-green-600"
              />
              <h3 className="text-2xl font-bold my-4 text-gray-800">
              User-Centric Design
              </h3>
              <p className="text-lg text-gray-700">
              We prioritize user experience in every project. Our designs are intuitive, ensuring that visitors can easily navigate your site, find information, and take action—whether that’s making a purchase or filling out a contact form.
              </p>
            </div>

            {/* Development and Testing */}
            <div className="flex flex-col items-center max-w-xs text-center p-8 bg-white border border-gray-200 rounded-lg">
              <FontAwesomeIcon
                icon={faCode}
                className="text-5xl mb-4 text-red-600"
              />
              <h3 className="text-2xl font-bold my-4 text-gray-800">
              Responsive Design
              </h3>
              <p className="text-lg text-gray-700">
              With mobile traffic on the rise, we create responsive websites that provide a seamless experience across all devices. Your website will look and function beautifully on desktops, tablets, and smartphones.
              </p>
            </div>

            {/* Launch and Support */}
            <div className="flex flex-col items-center max-w-xs text-center p-8 bg-white border border-gray-200 rounded-lg">
              <FontAwesomeIcon
                icon={faRocket}
                className="text-5xl mb-4 text-purple-600"
              />
              <h3 className="text-2xl font-bold my-4 text-gray-800">
              SEO Optimization
              </h3>
              <p className="text-lg text-gray-700">
              A great website is useless if no one can find it. Our website development services implement best practices from the start, helping your site rank higher in search engine results and attract organic traffic. .
              </p>
            </div>
          </div>
        </section>


        <section class="max-w-6xl mx-auto px-6 py-12 bg-white">
  <h1 class="text-3xl font-bold text-center text-gray-800 mb-6">
    Our Process: Design Thinking at Its Core
  </h1>
  <p class="text-lg text-gray-700 mb-8 text-center max-w-3xl mx-auto">
    At Adbrusher Media, our approach to website development services is built on a foundation of design thinking, ensuring a user-friendly and universally compatible experience. We strive to create a website that not only meets your expectations but exceeds them.
  </p>
  <ul class="grid grid-cols-1 md:grid-cols-2 gap-6">
    <li class="p-6 bg-gray-100 rounded-lg shadow hover:bg-gray-200 transition duration-200">
      <h2 class="font-semibold text-gray-800 mb-2">
        Dynamic & Mobile-Ready Websites
      </h2>
      <p class="text-gray-600">
        With the majority of users accessing websites via mobile devices, we prioritize creating dynamic and high-performance sites that are mobile-friendly. Our frameworks are designed to provide an optimal experience across all devices, ensuring fast load times and responsiveness.
      </p>
    </li>
    <li class="p-6 bg-gray-100 rounded-lg shadow hover:bg-gray-200 transition duration-200">
      <h2 class="font-semibold text-gray-800 mb-2">
        Convenient Navigation
      </h2>
      <p class="text-gray-600">
        We understand that user experience is crucial. Our team designs intuitive navigation systems that guide visitors effortlessly through your website. By crafting a well-organized homepage and clear pathways to core pages, we minimize bounce rates and keep users engaged.
      </p>
    </li>
    <li class="p-6 bg-gray-100 rounded-lg shadow hover:bg-gray-200 transition duration-200">
      <h2 class="font-semibold text-gray-800 mb-2">
        Performance Optimization
      </h2>
      <p class="text-gray-600">
        Once your website is live, we implement strategic analytics and heatmap tools to track user journeys and identify areas for improvement. Speed and performance are paramount; we continuously test and refine to ensure your site operates at peak efficiency.
      </p>
    </li>
    <li class="p-6 bg-gray-100 rounded-lg shadow hover:bg-gray-200 transition duration-200">
      <h2 class="font-semibold text-gray-800 mb-2">
        In-Depth Analytics
      </h2>
      <p class="text-gray-600">
        Our Clickstream and in-page analytics reporting allow you to monitor the effectiveness of your website over time. By analyzing key performance indicators, we can make data-driven adjustments to enhance your site’s functionality and achieve your goals.
      </p>
    </li>
    <li class="p-6 bg-gray-100 rounded-lg shadow hover:bg-gray-200 transition duration-200">
      <h2 class="font-semibold text-gray-800 mb-2">
        Cross-Browser Compatibility
      </h2>
      <p class="text-gray-600">
        Our development approach ensures your website performs flawlessly across all major browsers, providing a consistent experience for every user.
      </p>
    </li>
    <li class="p-6 bg-gray-100 rounded-lg shadow hover:bg-gray-200 transition duration-200">
      <h2 class="font-semibold text-gray-800 mb-2">
        Sitemap and Structure
      </h2>
      <p class="text-gray-600">
        We create comprehensive sitemaps that outline all accessible web pages for search engine crawlers. This not only enhances SEO but also ensures users can easily find and access all content.
      </p>
    </li>
  </ul>
</section>


        {/* Call to Action Section */}
        <section
          className="py-20 px-4 text-center bg-gradient-to-r from-pink-500 to-blue-600 text-white"
          data-aos="fade-up"
        >
          <h2 className="text-3xl font-semibold mb-6">
            Ready to Start Your Project?
          </h2>
          <p className="text-lg mb-8">
            Contact us today to discuss how we can help you develop your dream
            website. Our team is dedicated to making your website a standout
            success.
          </p>
          <button className="bg-white text-blue-600 py-2 px-6 rounded-full font-bold hover:bg-gray-100 transition">
            Get in Touch
          </button>
        </section>
      </div>
    </div>
  );
};

export default WebsiteDevelopment;
