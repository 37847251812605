import React, { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import { Link } from "react-router-dom";
import { FaPenFancy, FaBullhorn, FaRegEnvelope } from "react-icons/fa";
import { FaCrown, FaComments, FaChartLine, FaHandshake } from "react-icons/fa";
const ContentBranding = () => {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  return (
    <div>
      {/* Top Section with Background Image and Blue Tint */}
      <div
        className="relative h-1/2 py-32 flex items-center justify-start bg-cover bg-center px-4 sm:px-8 md:px-16 lg:px-32 lg:py-64"
        style={{
          backgroundImage: `url('https://ik.imagekit.io/tdlebsr5e/13531.jpg?updatedAt=1731399007834')`,
        }}
        data-aos="slide-in"
      >
        <div className="absolute inset-0 bg-black bg-opacity-80"></div>
        <div className="absolute inset-0 bg-blue-800 bg-opacity-10"></div>
        <div className="relative z-10 text-left">
          <h1
            className="text-4xl sm:text-5xl md:text-7xl text-white font-bold"
            data-aos="zoom-in-left"
          >
            Content Branding
          </h1>
          <p className="text-sky-400 my-6 font-semibold text-lg tracking-wider">
          Tell Your Story with Optimized Content Marketing Services
          </p>
        </div>
      </div>

      <div className="bg-white text-black">
        {/* Introduction Section */}
        <section className="py-20 px-4 text-center" data-aos="slide-up">
          <h1 className="text-4xl font-bold mb-6">
            Tell Your Story with Optimized Content Marketing Services
          </h1>
          <p className="text-lg mb-8">
            At Adbrusher Media, we provide specialized content marketing
            services that engage your audience, enhance brand visibility, and
            drive measurable results.
          </p>
          <Link to="/contact">
            <button className="bg-gradient-to-r from-purple-400 to-pink-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-lg">
              Get a FREE One-on-One Consultation
            </button>
          </Link>
        </section>

        {/* Importance of Content */}
        <section className="py-8 bg-gray-50 text-gray-900 flex justify-center  bg-gradient-to-r from-purple-300 to-pink-200 ">
          <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <h2 className="text-4xl font-bold mb-8 text-gray-800">
              Great Content Makes You KING
            </h2>
            <div className="space-y-6 text-left">
              <p className="text-lg leading-relaxed text-gray-700">
                Content marketing is a strategic approach focused on creating,
                publishing, and distributing valuable content to attract and
                retain a clearly defined audience. Unlike traditional
                advertising methods, which often interrupt potential customers,
                content marketing offers insightful and entertaining material
                that enriches their experience.
              </p>
              <p className="text-lg leading-relaxed text-gray-700">
                To make an impact on the Internet, the content your business
                produces is crucial. The saying "content is king" holds true now
                just as it did two decades ago. There are numerous ways to
                communicate your message through content marketing—from engaging
                copy that outlines your services to top-notch blog posts and
                eye-catching promotional graphics that capture your visitors'
                attention.
              </p>
            </div>
          </div>
        </section>

        {/* Why Invest in Content Strategy */}
        <section className="py-16 px-4 bg-gray-100" data-aos="slide-up">
          <h2 className="text-4xl py-8 text-black font-semibold text-center mb-10">
            Why Invest in Content Strategy Development For Your Brand?
          </h2>
          <div className="flex flex-wrap justify-center text-lg leading-7 max-w-7xl mx-auto">
            <div className="md:w-1/2 lg:w-1/4 px-6 mb-6 text-center transition-transform transform hover:scale-105">
              <div className="mb-4 flex justify-center">
                <FaCrown className="text-5xl text-purple-600" />
              </div>
              <p className="mb-6">
                <strong>Establish Your Brand Authority:</strong> High-quality
                content positions your brand as an industry leader. By
                consistently sharing valuable insights, you build credibility
                and attract new customers while strengthening loyalty among
                existing ones.
              </p>
            </div>

            <div className="md:w-1/2 lg:w-1/4 px-6 mb-6 text-center transition-transform transform hover:scale-105">
              <div className="mb-4 flex justify-center">
                <FaComments className="text-5xl text-blue-600" />
              </div>
              <p className="mb-6">
                <strong>Boost Customer Engagement:</strong> Engaging content
                encourages interaction through social shares, comments, and
                inquiries.
              </p>
            </div>

            <div className="md:w-1/2 lg:w-1/4 px-6 mb-6 text-center transition-transform transform hover:scale-105">
              <div className="mb-4 flex justify-center">
                <FaChartLine className="text-5xl text-green-600" />
              </div>
              <p className="mb-6">
                <strong>Improve Your SEO and Online Visibility:</strong> Search
                engines prioritize fresh, relevant content. By using SEO best
                practices in your strategy, you can enhance visibility, drive
                organic traffic, and improve search rankings, resulting in more
                conversions.
              </p>
            </div>

            <div className="md:w-1/2 lg:w-1/4 px-6 mb-6 text-center transition-transform transform hover:scale-105">
              <div className="mb-4 flex justify-center">
                <FaHandshake className="text-5xl text-red-600" />
              </div>
              <p className="mb-6">
                <strong>Drive High-Quality Conversions:</strong> Well-crafted
                content effectively guides potential customers through the
                buyer's journey. By addressing their needs, you can
                significantly boost conversion rates and turn casual visitors
                into loyal customers.
              </p>
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section
          className="py-8 px-4 bg-gradient-to-r from-purple-400 to-pink-600 text-center text-white"
          data-aos="slide-up"
        >
          <h2 className="text-4xl font-semibold mb-4">
            A Great Idea Needs EXCEPTIONAL Content
          </h2>
          <Link to="/Company">
            <button className="bg-blue-700 hover:bg-blue-900 text-white font-semibold py-2 px-4 rounded-lg">
              Get Started
            </button>
          </Link>
        </section>

        {/* Our Content Services */}
        <section className="py-16 px-4 bg-gray-50" data-aos="slide-up">
      <h2 className="text-4xl font-semibold text-center mb-12 text-gray-800">
        Our Content Marketing Services To Spark Success For You
      </h2>
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-10 text-lg leading-8">
        
        <div className="mb-8">
          <p className="font-semibold text-xl">
            Strategic Content Development:
          </p>
          <p>
            Our team works closely with you to create a comprehensive content strategy that aligns with your business objectives. We identify your target audience, set measurable goals, and outline the types of content that will resonate most.
          </p>
        </div>

        <div className="mb-8">
          <p className="font-semibold text-xl">
            Engaging Blog Writing:
          </p>
          <p>
            Our team produces informative and engaging blog posts and articles that provide real value to your readers. We conduct in-depth research to ensure your content is relevant, well-informed, and aligned with industry trends.
          </p>
        </div>

        <div className="mb-8">
          <p className="font-semibold text-xl">
            Dynamic Social Media Content Creation:
          </p>
          <p>
            Captivating social media content is crucial for audience engagement. Our team develops tailored posts designed to capture attention and encourage interaction, maximizing your reach across platforms.
          </p>
        </div>

        <div className="mb-8">
          <p className="font-semibold text-xl">
            Professional Copywriting Services:
          </p>
          <p>
            High-quality copy is essential for effective content marketing. Our copywriting services ensure your messaging is clear, persuasive, and optimized for both user experience and SEO. From web pages to marketing materials, we craft content that captivates and converts.
          </p>
        </div>

        <div className="mb-8">
          <p className="font-semibold text-xl">
            High-Quality Video Content Production:
          </p>
          <p>
            Video is one of the most powerful tools in content marketing today. Our team produces engaging videos that effectively convey your brand story, showcase your products, and captivate your audience in a visually dynamic way.
          </p>
        </div>

        <div className="mb-8">
          <p className="font-semibold text-xl">
            Effective Content Distribution and Promotion:
          </p>
          <p>
            Creating outstanding content is just the beginning. We employ various channels and strategies to promote your content, ensuring it reaches the right audience and maximizes its impact.
          </p>
        </div>

      </div>
    </section>
      </div>
    </div>
  );
};

export default ContentBranding;
