import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const ChooseUs = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <section className="py-12 bg-gradient-to-r from-purple-400 to-pink-600 ...">
      <div className="text-center mb-8">
        <h2 className="text-3xl font-bold text-black">Let the Numbers Speak for Us!</h2>
        <p className="text-gray-600 mt-4">Here’s a glimpse of our impactful results:</p>
      </div>

      <div className="flex flex-wrap justify-center gap-6">
        <div data-aos="fade-up" className="bg-white p-6 rounded-lg shadow-md w-80">
          <h3 className="text-xl font-bold text-black">8,324 Leads Generated</h3>
          <p className="text-sm text-gray-500 mt-2">Growth: 78%</p>
          <p className="mt-4 text-gray-600">
            Our targeted campaigns have proven effective in attracting and converting potential customers, driving impressive lead generation.
          </p>
        </div>
        <div data-aos="fade-up" data-aos-delay="200" className="bg-white p-6 rounded-lg shadow-md w-80">
          <h3 className="text-xl font-bold text-black">33,124 Website Visits</h3>
          <p className="text-sm text-gray-500 mt-2">Increase: 82%</p>
          <p className="mt-4 text-gray-600">
            Innovative SEO and content strategies have significantly boosted our web traffic, allowing your brand to reach a broader audience.
          </p>
        </div>
        <div data-aos="fade-up" data-aos-delay="400" className="bg-white p-6 rounded-lg shadow-md w-80">
          <h3 className="text-xl font-bold text-black">14,267 Brand Impressions</h3>
          <p className="text-sm text-gray-500 mt-2">Surge: 96%</p>
          <p className="mt-4 text-gray-600">
            Our focused marketing efforts have dramatically enhanced your brand's visibility, making a strong impression on your target audience.
          </p>
        </div>
      </div>
    </section>
  );
};

export default ChooseUs;
