import React, { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCompass,
  faPaintBrush,
  faCode,
  faRocket,
} from "@fortawesome/free-solid-svg-icons";

const PPC = () => {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  return (
    <div className="bg-gray-50">
      {/* Top Section with Background Image and Blue Tint */}
      <div
        className="relative h-1/2 py-32 flex items-center justify-start bg-cover bg-center px-4 sm:px-8 md:px-16 lg:px-32 lg:py-64"
        style={{
          backgroundImage: `url('https://images.pexels.com/photos/1181406/pexels-photo-1181406.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')`,
        }}
        data-aos="slide-in"
      >
        <div className="absolute inset-0 bg-black bg-opacity-80"></div>
        <div className="absolute inset-0 bg-blue-800 bg-opacity-10"></div>
        <div className="relative z-10 text-left">
          <h1
            className="text-xl sm:text-5xl md:text-7xl text-white font-bold"
            data-aos="zoom-in-left"
            // data-aos-offset="500"
            // data-aos-duration="500"
          >
            PPC Ads Campaign
          </h1>
          <p className="text-sky-400 my-6 font-semibold text-lg md:text-md tracking-wider">
          Powerful Performance-Based PPC Marketing Services
          </p>
        </div>
      </div>

      <div className="bg-white text-black">
        {/* Introduction Section */}
        <section
          className="py-24 px-6 md:py-32 md:px-12 lg:px-24 xl:px-36 text-center bg-gray-50"
          data-aos="slide-up"
        >
          <h1 className="text-2xl md:text-xl lg:text-5xl font-extrabold mb-8 md:mb-10 text-gray-800 leading-tight max-w-3xl mx-auto">
            Powerful Performance-Based PPC Marketing Services
          </h1>
          <p className="text-base md:text-lg lg:text-xl text-gray-600 mb-12 max-w-2xl mx-auto leading-relaxed">
            At Adbrusher Media, we specialize in delivering tailored PPC
            marketing services designed to help you achieve your business goals
            and maximize your ROI.
          </p>

          <Link to="/contact">
            <button className="bg-pink-500 hover:bg-pink-600 text-white font-semibold py-3 px-8 rounded-lg transition-all duration-300 shadow-lg hover:shadow-2xl transform hover:-translate-y-1">
              Get Started
            </button>
          </Link>
        </section>
        <div className="pt-16 px-6 md:px-12 lg:px-20 xl:px-32  text-center rounded-lg  bg-gradient-to-r from-purple-300 to-pink-200 ">
          <h2 className="text-3xl md:text-xl lg:text-5xl font-bold text-gray-800 mb-6 leading-tight ">
            Unlock the Power of Pay-Per-Click Advertising
          </h2>
          <p className="text-base md:text-lg lg:text-xl text-gray-600 max-w-5xl mx-auto leading-relaxed   ">
            Pay-Per-Click (PPC) marketing revolutionizes online advertising,
            allowing businesses to pay a fee each time someone clicks on their
            ad. This innovative approach not only boosts your visibility on
            search engines and social media platforms but also enables precise
            targeting, making it an essential tool for businesses eager to
            expand their online footprint rapidly.
          </p>
        </div>

        <section
          className="py-20 px-8 md:px-16 lg:px-24 xl:px-32 flex flex-col md:flex-row md:space-x-12 items-center  bg-gradient-to-r from-purple-300 to-pink-200 "
          data-aos="slide-up"
        >
          <div className="md:w-1/2 flex flex-col items-center text-center md:items-start md:text-left mb-10 md:mb-0">
            <p className="text-lg mb-8 max-w-lg mx-auto md:mx-0">
              <strong>Immediate Traffic and Results:</strong> Unlike traditional
              organic marketing strategies that can take time to yield results,
              PPC delivers instant traffic to your website. The moment your
              campaign goes live, your ads can start appearing in search
              results, positioning your business to attract potential customers
              immediately.
            </p>
            <p className="text-lg mb-8 max-w-lg mx-auto md:mx-0">
              <strong>Targeted Advertising:</strong> With PPC, you can hone in
              on specific demographics, interests, and online behaviors. This
              means your ads are showcased only to individuals most likely to be
              interested in your products or services, significantly increasing
              your conversion rates and maximizing your return on investment.
            </p>
            <div className="flex justify-center md:justify-start mb-8">
              <img
                src="https://images.pexels.com/photos/3184290/pexels-photo-3184290.jpeg"
                alt="PPC Service"
                className="max-h-96 rounded-lg transition-transform transform hover:scale-105"
              />
            </div>
          </div>

          <div className="md:w-1/2 flex flex-col items-center text-center md:items-start md:text-left mt-10 md:mt-0">
            <div className="flex justify-center md:justify-start mb-8">
              <img
                src="https://images.pexels.com/photos/3184465/pexels-photo-3184465.jpeg"
                alt="PPC Benefits"
                className="max-h-96 rounded-lg transition-transform transform hover:scale-105"
              />
            </div>
            <p className="text-lg mb-8 max-w-lg mx-auto md:mx-0">
              <strong>Budget Control:</strong> One of the greatest advantages of
              PPC marketing is the complete control it offers over your
              advertising budget. You can set daily spending limits, adjust bids
              based on campaign performance, and allocate your budget to the
              most effective campaigns, ensuring you get the best bang for your
              buck.
            </p>
            <p className="text-lg mb-8 max-w-lg mx-auto md:mx-0">
              <strong>Measurable and Optimizable:</strong> PPC marketing
              provides unparalleled trackability, enabling you to measure the
              performance of your campaigns in real time. This data-driven
              approach allows you to make informed decisions and continually
              optimize your strategy for better results.
            </p>
          </div>
        </section>

        <section className="w-full bg-gradient-to-r from-purple-600 to-pink-500 lg:p-16 p-8 text-white">
          <div className="container mx-auto">
            <h2 className="text-4xl md:text-xl font-extrabold mb-8 text-center">
              Achieve Immediate Results with Our PPC Marketing Services
            </h2>
            <h3 className="text-xl font-semibold mb-6 text-center">
              Elevate Your Strategy with Responsive Search Ads
            </h3>

            <p className="mb-8 text-lg text-gray-100 text-center">
              Responsive Search Ads (RSAs) are dynamic text-based advertisements
              that appear prominently above organic search results on Google.
              These ads provide potential customers with essential information
              about your business and feature a convenient link for mobile users
              to call you directly.
            </p>

            <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 text-lg">
              <li className="flex items-center bg-white bg-opacity-20 p-4 rounded-lg">
                <span className="text-green-400 mr-3 text-xl">✔️</span>
                <span>
                  Text Ads with Business Information: Highlight key details
                  about your services.
                </span>
              </li>
              <li className="flex items-center bg-white bg-opacity-20 p-4 rounded-lg">
                <span className="text-green-400 mr-3 text-xl">✔️</span>
                <span>
                  Mobile Call Link: Encourage immediate engagement with a simple
                  click.
                </span>
              </li>
              <li className="flex items-center bg-white bg-opacity-20 p-4 rounded-lg">
                <span className="text-green-400 mr-3 text-xl">✔️</span>
                <span>
                  Prime Visibility: Positioned above organic search results for
                  maximum exposure.
                </span>
              </li>
            </ul>

            <h3 className="text-xl font-semibold mt-10 mb-6 text-center">
              Capture Attention with Shopping Ads
            </h3>
            <p className="mb-8 text-lg text-gray-100 text-center">
              Shopping Ads are a powerful way to showcase your products directly
              in Google search results.
            </p>

            <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 text-lg">
              <li className="flex items-center bg-white bg-opacity-20 p-4 rounded-lg">
                <span className="text-green-400 mr-3 text-xl">✔️</span>
                <span>
                  Product Images: Visually appealing visuals to attract
                  shoppers.
                </span>
              </li>
              <li className="flex items-center bg-white bg-opacity-20 p-4 rounded-lg">
                <span className="text-green-400 mr-3 text-xl">✔️</span>
                <span>
                  Price Information: Clear pricing to aid decision-making.
                </span>
              </li>
              <li className="flex items-center bg-white bg-opacity-20 p-4 rounded-lg">
                <span className="text-green-400 mr-3 text-xl">✔️</span>
                <span>
                  Merchant Details: Brand recognition with your store name.
                </span>
              </li>
            </ul>

            <h3 className="text-xl font-semibold mt-10 mb-6 text-center">
              Boost Your Brand with Display Ads
            </h3>
            <p className="mb-8 text-lg text-gray-100 text-center">
              Display Ads are visually striking advertisements that appear
              across the expansive Google Display Network.
            </p>

            <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 text-lg">
              <li className="flex items-center bg-white bg-opacity-20 p-4 rounded-lg">
                <span className="text-green-400 mr-3 text-xl">✔️</span>
                <span>
                  Wide Reach: Over 2 million websites in the Google Display
                  Network.
                </span>
              </li>
              <li className="flex items-center bg-white bg-opacity-20 p-4 rounded-lg">
                <span className="text-green-400 mr-3 text-xl">✔️</span>
                <span>
                  Diverse Formats: Customize your ads to suit your audience.
                </span>
              </li>
              <li className="flex items-center bg-white bg-opacity-20 p-4 rounded-lg">
                <span className="text-green-400 mr-3 text-xl">✔️</span>
                <span>
                  Increased Brand Awareness: Engage customers as they browse.
                </span>
              </li>
            </ul>

            <h3 className="text-xl font-semibold mt-10 mb-6 text-center">
              Re-Engage Customers with Remarketing Ads
            </h3>
            <p className="mb-8 text-lg text-gray-100 text-center">
              Create ads to reconnect with customers who have already shown
              interest.
            </p>

            <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 text-lg">
              <li className="flex items-center bg-white bg-opacity-20 p-4 rounded-lg">
                <span className="text-green-400 mr-3 text-xl">✔️</span>
                <span>
                  Target Past Visitors: Show ads only to engaged users.
                </span>
              </li>
              <li className="flex items-center bg-white bg-opacity-20 p-4 rounded-lg">
                <span className="text-green-400 mr-3 text-xl">✔️</span>
                <span>
                  Follow Users Across the Web: Keep your brand top-of-mind.
                </span>
              </li>
            </ul>
          </div>
        </section>

        {/* Call to Action Section */}

        {/* Final Call to Action */}
        <section class="max-w-6xl mx-auto px-6 py-12 bg-white">
          <h1 class="text-3xl font-bold text-center text-gray-800 mb-6">
            Maximize ROI with Tailored PPC Marketing Solutions
          </h1>
          <p class="text-lg text-gray-700 mb-8 text-center max-w-3xl mx-auto">
            At Adbrusher Media, we offer a comprehensive suite of PPC marketing
            services designed to drive results for your business. Each service
            is crafted to meet your specific goals, focusing on key strategies
            that deliver high ROI and growth.
          </p>
          <ul class="grid grid-cols-1 md:grid-cols-2 gap-6">
            <li class="p-6 bg-gray-100 rounded-lg shadow hover:bg-gray-200 transition duration-200">
              <h2 class="font-semibold text-gray-800 mb-2">
                Campaign Strategy Development
              </h2>
              <p class="text-gray-600">
                We begin by understanding your business objectives and target
                audience. Our team then crafts a customized PPC strategy that
                aligns with your goals and maximizes your ad spend.
              </p>
            </li>
            <li class="p-6 bg-gray-100 rounded-lg shadow hover:bg-gray-200 transition duration-200">
              <h2 class="font-semibold text-gray-800 mb-2">
                Keyword Research and Selection
              </h2>
              <p class="text-gray-600">
                Effective PPC campaigns hinge on the right keywords. Our experts
                conduct extensive research to identify high-value keywords that
                will attract relevant traffic to your site.
              </p>
            </li>
            <li class="p-6 bg-gray-100 rounded-lg shadow hover:bg-gray-200 transition duration-200">
              <h2 class="font-semibold text-gray-800 mb-2">
                Ad Creation and Optimization
              </h2>
              <p class="text-gray-600">
                Our creative team designs compelling ad copy and visuals that
                grab attention and drive clicks. We continuously test and
                optimize your ads to improve performance and increase conversion
                rates.
              </p>
            </li>
            <li class="p-6 bg-gray-100 rounded-lg shadow hover:bg-gray-200 transition duration-200">
              <h2 class="font-semibold text-gray-800 mb-2">
                Landing Page Optimization
              </h2>
              <p class="text-gray-600">
                To ensure maximum conversions, we optimize your landing pages
                for a seamless user experience. Our team implements best
                practices based on user behavior analysis to create
                high-converting landing pages.
              </p>
            </li>
            <li class="p-6 bg-gray-100 rounded-lg shadow hover:bg-gray-200 transition duration-200">
              <h2 class="font-semibold text-gray-800 mb-2">
                Google Ads Management
              </h2>
              <p class="text-gray-600">
                As part of our PPC marketing services, we specialize in
                comprehensive Google Ads management. From setting up your
                campaigns to ongoing monitoring and adjustments, we handle it
                all to ensure your ads perform at their best.
              </p>
            </li>
            <li class="p-6 bg-gray-100 rounded-lg shadow hover:bg-gray-200 transition duration-200">
              <h2 class="font-semibold text-gray-800 mb-2">
                Performance Monitoring and Reporting
              </h2>
              <p class="text-gray-600">
                We provide continuous monitoring of your campaigns, analyzing
                key performance metrics to identify opportunities for
                improvement. Our detailed reports keep you informed about your
                campaign’s effectiveness and ROI.
              </p>
            </li>
          </ul>
        </section>
        <section
          className="py-24 px-8 md:px-16 lg:px-24 xl:px-32 bg-gradient-to-r from-purple-100 to-pink-200 text-center"
          data-aos="slide-up"
        >
          <h2 className="text-2xl md:text-3xl font-semibold mb-4 text-gray-700">
            Drive Conversions and Visibility with Comprehensive PPC Marketing
          </h2>
          <h2 className="text-3xl md:text-4xl font-bold mb-10 text-gray-800 leading-snug">
            Let’s craft a winning PPC strategy that propels your brand forward.
          </h2>
          <Link to="/contact">
            <button className="bg-pink-400 hover:bg-pink-500 text-white font-semibold py-3 px-8 rounded-lg shadow-md transition-transform duration-300 transform hover:scale-105">
              Get Your Free Consultation
            </button>
          </Link>
        </section>

        <section className="py-16 px-4 text-center bg-gray-50">
          <h2 className="text-xl font-semibold mb-8 text-gray-800">
            Partner with Adbrusher Media: Your Trusted Pay-Per-Click Advertising
            Experts
          </h2>
          <p className="text-lg md:text-xl mb-10 text-gray-600">
            When it comes to selecting a Pay-per-click advertising company, the
            right partner can make all the difference. Here’s why Adbrusher
            Media is the ideal choice for your PPC needs:
          </p>

          <div className="flex flex-wrap justify-center gap-8 mx-auto">
            {/* Expertise and Experience */}
            <div className="flex flex-col items-center max-w-xs text-center p-8 bg-white border border-gray-200 rounded-lg">
              <FontAwesomeIcon
                icon={faRocket}
                className="text-5xl mb-4 text-blue-600"
              />{" "}
              {/* Replace with appropriate icon */}
              <h3 className="text-2xl font-bold my-4 text-gray-800">
                Expertise and Experience
              </h3>
              <p className="text-lg text-gray-700">
                Our team of PPC specialists brings years of experience in
                developing effective advertising strategies tailored to various
                industries. We know what works and how to achieve optimal
                results.
              </p>
            </div>

            {/* Data-Driven Strategies */}
            <div className="flex flex-col items-center max-w-xs text-center p-8 bg-white border border-gray-200 rounded-lg">
              <FontAwesomeIcon
                icon={faCode}
                className="text-5xl mb-4 text-green-600"
              />{" "}
              {/* Replace with appropriate icon */}
              <h3 className="text-2xl font-bold my-4 text-gray-800">
                Data-Driven Strategies
              </h3>
              <p className="text-lg text-gray-700">
                We leverage data and analytics to inform every decision we make.
                This ensures that your campaigns are continuously optimised for
                maximum performance and ROI.
              </p>
            </div>

            {/* Transparent Communication */}
            <div className="flex flex-col items-center max-w-xs text-center p-8 bg-white border border-gray-200 rounded-lg">
              <FontAwesomeIcon
                icon={faPaintBrush}
                className="text-5xl mb-4 text-red-600"
              />{" "}
              {/* Replace with appropriate icon */}
              <h3 className="text-2xl font-bold my-4 text-gray-800">
                Transparent Communication
              </h3>
              <p className="text-lg text-gray-700">
                We prioritise clear and open communication with our clients.
                You’ll receive regular updates and detailed reports, keeping you
                informed about your campaign’s progress.
              </p>
            </div>

            {/* Customised Solutions */}
            <div className="flex flex-col items-center max-w-xs text-center p-8 bg-white border border-gray-200 rounded-lg">
              <FontAwesomeIcon
                icon={faCompass}
                className="text-5xl mb-4 text-purple-600"
              />{" "}
              {/* Replace with appropriate icon */}
              <h3 className="text-2xl font-bold my-4 text-gray-800">
                Customised Solutions
              </h3>
              <p className="text-lg text-gray-700">
                Every business is unique, and so are its marketing needs. Our
                PPC marketing services are tailored to align with your specific
                goals, ensuring that we meet your individual requirements.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default PPC;
